<script setup lang="ts">
import { useMediaQuery } from '@vueuse/core';
import { debounce, isEqual } from 'lodash-es';

import { useSearchResults } from '../api/getSearchResults';
import { categories } from '../constants/categories';
import type { SearchResults } from '../types';
import isEmptyObject from '~/utilities/isEmptyObject';
import { capitalize } from '~/utilities/string-utilities';
import type { Category, SearchRequestBody } from '~/features/search';

const props = withDefaults(defineProps<Properties>(), {
  placeholder: '',
  selectedLocation: 0,
  disabled: false,
});

const { $store } = useNuxtApp();

const isMobile = useMediaQuery('(max-width: 640px)');
const color = $store.getters['ContextStore/accentColor'];
const __ = useTranslate();

interface Properties {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  selectedLocation: number;
}
const searchValue = ref<string>();
const showResults = ref(false);
const checks = ref<string[]>(categories.map(element => element.type));
const router = useRouter();
const searchInputField = ref();
function goToSearchResults(selectedLocation: number, item?: Category) {
  if (searchValue.value && searchValue.value.trim()) {
    searchInputField.value.blur();
    item && item.redirect
      ? router.push(
          `/search?q=${searchValue.value}&types=${computeTypeParameters(
            item,
          )}&locationId=${selectedLocation}`,
      )
      : router.push(
          `/search?q=${
            searchValue.value
          }&types=${computeTypeParameters()}&locationId=${selectedLocation}`,
      );

    showResults.value = false;
  }
}

const checkedItems = computed(() =>
  categories.filter(category => checks.value.includes(category.type)),
);
function computeTypeParameters(item?: Category) {
  return item ? item.type : checkedItems.value.map(item => item.type).join(',');
}
function goToItemDetails() {
  showResults.value = false;
}

const filterState = ref({} as SearchRequestBody);
const { data: searchResponse } = useSearchResults(filterState);
const noSearchResults = computed(() => {
  return !searchResponse || isEmptyObject(searchResponse.value);
});

function handleFilterChange(category: Category) {
  if (checkedItems.value.includes(category))
    checks.value = checks.value.filter(check => check !== category.type);
  else
    checks.value.push(category.type);
}
function onSearchbarFocus() {
  if (searchValue.value && searchValue.value.length > 0)
    showResults.value = true;
}
const handleInput = debounce((event, selectedLocation) => {
  searchValue.value = event.target.value.trim();
  if (searchValue.value !== '' && checks.value.length > 0)
    showResults.value = false;

  filterState.value = {
    searchQuery: searchValue.value,
    size: 100,
    includeTypes: checks.value,
    filter: {
      locationIds: [selectedLocation],
    },
  };
}, 500);

watch(searchResponse, (newValue) => {
  showResults.value = !!newValue && !isEqual(newValue, {});
});

function closeSearch() {
  showResults.value = false;
}

function showMoreResultsButton(category: Category): boolean {
  if (
    !category.redirect
    || !searchResponse?.value
    || !searchResponse?.value[category.type as keyof SearchResults]
  )
    return false;

  return (
    (searchResponse?.value[category.type as keyof SearchResults]?.length ?? 0)
    > category.resultsShown
  );
}
</script>

<template>
  <div v-click-outside="closeSearch" class="flex w-full flex-col">
    <div class="relative flex flex-col">
      <div class=" flex w-full flex-row-reverse">
        <div class="relative inline-block w-full">
          <div
            class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-4"
          >
            <trailblazer-icon
              class="inline-block"
              name="search"
              :accent="disabled ? 'var(--tb-color-text-weaker)' : color"
            />
          </div>
          <input
            id="searchBar"
            ref="searchInputField"
            v-model="searchValue"
            class="dark:bg-dark-search-input-background w-full appearance-none rounded border-2 border-solid border-primary border-opacity-0 bg-neutral-4 px-14 py-4 text-base shadow focus-visible:border-2 focus-visible:border-solid focus-visible:border-primary disabled:bg-neutral-3 dark:text-white"
            :placeholder="__(props.placeholder)"
            :disabled="disabled"
            @focusin="onSearchbarFocus"
            @input="handleInput($event, selectedLocation)"
            @keyup.enter="goToSearchResults(selectedLocation)"
          >
          <div
            class="absolute inset-y-0 right-0 z-10 flex items-center"
            @click="closeSearch"
          >
            <trailblazer-dropdown
              position="bottom-start"
              class="border-l-2 border-solid border-neutral-2-200 dark:border-menu-item-background"
            >
              <div slot="trigger" class="cursor-pointer p-4">
                <trailblazer-icon name="ellipsis-vertical" />
              </div>
              <div class="mt-3">
                <span class="p-6 text-neutral-2">{{ __('Search in:') }} </span>
                <div class="mt-8 space-y-3">
                  <div
                    v-for="category in categories"
                    :key="category.title"
                    class="px-2"
                  >
                    <trailblazer-dropdown-item
                      @select.stop="handleFilterChange(category)"
                    >
                      <trailblazer-icon slot="start" :name="category.icon" />
                      <span class="text-neutral-1">
                        {{ __(category.title) }}
                      </span>
                      <trailblazer-checkbox
                        slot="end"
                        hide-label
                        :checked="checkedItems.includes(category)"
                      />
                    </trailblazer-dropdown-item>
                  </div>
                </div>
              </div>
            </trailblazer-dropdown>
          </div>
        </div>
        <br>
        <label
          v-if="props.label"
          class="mb-2 inline-block font-medium"
          :class="{ 'text-neutral-2': props.disabled }"
        >
          {{ __(props.label) }}
        </label>
      </div>
      <div
        v-if="searchValue && showResults"
        class="absolute z-40 mt-16 flex h-auto w-full flex-col items-start self-end rounded bg-neutral-2-50 px-6 shadow-md dark:bg-dark-secondary"
      >
        <perfect-scrollbar v-if="!noSearchResults" class="w-full">
          <div class="pr-3">
            <div
              v-for="item in categories"
              :key="item"
              class="relative flex w-full flex-col"
            >
              <div
                v-if="
                  checks?.includes(item.type) && searchResponse?.[item.type]
                "
              >
                <div
                  class="border-b-2 border-solid border-neutral-2-200 py-3 dark:border-menu-item-background"
                >
                  <div class="flex w-full flex-row">
                    <a
                      class="mr-auto font-sans text-sm leading-5 text-neutral-2-500 dark:text-white"
                    >{{ __(capitalize(item.title)) }} ({{
                      searchResponse?.[item.type]?.length ?? 0
                    }})</a>
                    <div
                      v-if="showMoreResultsButton(item)"
                      class="flex flex-row"
                    >
                      <button
                        type="button"
                        class="text-sans place-self-end bg-transparent text-base font-medium leading-6 accent-font"
                        @click="goToSearchResults(selectedLocation, item)"
                      >
                        {{ __('View all') }} {{ capitalize(item.title) }} ({{
                          searchResponse?.[item.type]?.length ?? 0
                        }})
                      </button>
                      <div
                        v-if="!isMobile"
                        class="pointer-events-none relative inset-y-0 left-0 ml-3 flex items-center"
                      >
                        <trailblazer-icon
                          class="inline-block"
                          name="arrow-right"
                          :accent="
                            disabled ? 'var(--tb-color-text-weaker)' : color
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex-col">
                  <div
                    v-for="result in searchResponse[item.type]?.slice(
                      0,
                      item.resultsShown,
                    )"
                    :key="result.model.id"
                    class="w-full"
                  >
                    <component
                      :is="item.component"
                      class="w-full"
                      :result="result"
                      :searched-text="searchValue"
                      :on-click="goToItemDetails"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
        <div v-else class="flex w-full px-6">
          <a
            class="py-6 font-sans leading-5 text-neutral-2-500 dark:text-white"
          >{{ __('No results found') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ps {
  max-height: calc(100vh - 200px);
}
</style>
